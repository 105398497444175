<template>
  <div class="ajax-search-box">
    <div class="ajax-search-input">
      <va-input
        v-model="queryString"
        :placeholder="placeholder"
        :disable="disable"
        removable
        v-if="!selectedItem"
      >
        <va-icon name="fa fa-search" slot="prepend" />
      </va-input>
      <va-input v-model="selectedItem" removable :disable="true" v-else>
        <va-icon name="fa fa-search" slot="prepend" />
      </va-input>
    </div>
    <div class="ajax-results-holder" v-if="resultsAvailable">
      <!-- Maybe need a button to hide results -->
      <p class="result-row" v-for="row in results" @click="selectItem(row)">
        {{ row.title }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AjaxSearchBox',
  props: {
    maxResults: {
      type: Number,
      default: 10,
    },
    selectProperty: {
      type: String,
      default: null,
    },
    minQueryLength: {
      type: Number,
      default: 3,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    apiCallFunction: {
      type: Function,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      results: [],
      queryString: '',
      selectedItem: null,
    };
  },
  watch: {
    async queryString(newVal, _) {
      if (newVal.length == 0) {
        this.results = [];
        return;
      }

      if (newVal.length >= this.minQueryLength) {
        this.results = await this.getResults();
      }
    },
  },
  computed: {
    resultsAvailable() {
      return this.results.length > 0;
    },
  },
  methods: {
    async getResults() {
      const results = await this.apiCallFunction(this.queryString);
      return results.slice(0, this.maxResults);
    },
    selectItem(item) {
      this.results = [];
      // Check if item has attribute
      if (this.selectProperty && item.hasOwnProperty(this.selectProperty)) {
        this.selectedItem = item[this.selectProperty];
      } else {
        this.selectedItem = JSON.stringify(item);
      }
      this.$emit('select', item);
    },
  },
};
</script>

<style lang="scss">
.ajax-results-holder {
  background-color: #f5f8f9;
  border-radius: 5px;
  border: 1px solid #babfc2;
  -webkit-box-shadow: 6px 6px 11px -3px rgba(130, 130, 130, 0.62);
  -moz-box-shadow: 6px 6px 11px -3px rgba(130, 130, 130, 0.62);
  box-shadow: 6px 6px 11px -3px rgba(130, 130, 130, 0.62);
}

.result-row {
  border-bottom: 1px solid #babfc2;
  padding: 3px;

  &:hover {
    background-color: #e2d589;
    border-bottom: 1px solid #686868;
  }
}
</style>
