<template>
  <va-card>
    <div class="row align--center" v-if="canAddScraper">
      <div class="flex xs12 md6" v-if="canAddScraper">
        <add-scraper-modal />
      </div>
    </div>
    <div v-if="canListScraper">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <!-- <va-input
            v-model="term"
            :placeholder="$t('tables.searchByName')"
            @keydown="searchManga"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input> -->
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-select
            v-model="perPage"
            :label="$t('tables.perPage')"
            :options="perPageOptions"
            noClear
          />
        </div>
      </div>
      <loader v-if="isLoading" />
      <data-table
        ref="scrapertable"
        :fields="fields"
        :data="mangaScrapers"
        :per-page="pagination.limit"
        :total-pages="pagination.pages"
        :currentPage="pagination.currentPage"
        :api-mode="true"
        :expandableRow="expandableRow"
        @page-selected="loadAllMangaScrapers"
        v-else
      >
        <template slot="scraper" slot-scope="props">
          <a
            :href="props.rowData.scraper.linkFormat | scrapingSiteOriginURL"
            target="_blank"
          >
            <va-badge outline color="warning">{{
              props.rowData.scraper.name
            }}</va-badge>
          </a>
          <a :href="getMangaSiteURL(props.rowData)" target="_blank">
            <va-icon name="fa fa-external-link" :size="21" />
          </a>
          <a :href="getCometMangaSiteURL(props.rowData)" target="_blank">
            {{ props.rowData.manga.title }}
          </a>
        </template>
        <template slot="totalChaptersScraped" slot-scope="props">
          {{
            props.rowData.totalChaptersScraped
              ? props.rowData.totalChaptersScraped
              : 'NA'
          }}
        </template>
        <template slot="lastScrapedAt" slot-scope="props">
          <p v-if="props.rowData.lastSuccessfulScrap">
            {{ props.rowData.lastSuccessfulScrap | momentDaysAgo }}
          </p>
          <p v-else>NA</p>
        </template>
        <template slot="actions" slot-scope="props">
          <scraper-row-action
            :mangaScraper="props.rowData"
            :scrapers="scrapers"
            :isDetailedRowVisible="
              isDetailedRowVisbleForRowId(props.rowData.id)
            "
            @moreInfoButtonClick="handleMoreInfoButtonClick"
            @update="updateMangaScrapersArray"
            @delete="deleteFromMangaScrapersArray"
          />
        </template>
      </data-table>
    </div>
    <access-denied v-else />
  </va-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { isAllowed } from '../../mixins/utils';
import {
  validDateFormat,
  scrapingSiteOriginURL,
  momentDaysAgo,
} from '../../mixins/filters';

import { MANGA_SCRAPERS_TABLE_FIELDS } from '../../constants/tableFields';

import DataTable from '../../components/DataTable';
import Loader from '../../components/Loader';
import AddScraperModal from './AddScraperModal';
import {
  ScraperRowAction,
  ScrapingTasksExpandedRow,
} from '../../components/ScraperRow';

import {
  fetchScrapers,
  fetchAllMangaScrapers,
} from '../../apollo/api/mangaScrapers';

export default {
  components: { AddScraperModal, Loader, DataTable, ScraperRowAction },
  data() {
    return {
      term: '',
      mangaScrapers: [],
      scrapers: [],
      perPageOptions: ['20', '40', '100', '200', '500', 'All'],
      perPage: '20',
      pagination: {
        limit: 20,
        currentPage: 1,
        pages: 0,
        total: 0,
      },
      expandableRow: ScrapingTasksExpandedRow,
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'ACCESS_CODES', 'RESOURCE_CODES']),
    canListScraper() {
      return isAllowed([this.RESOURCE_CODES.SCRAPER([this.ACCESS_CODES.LIST])]);
    },
    canAddScraper() {
      return isAllowed([
        this.RESOURCE_CODES.SCRAPER([this.ACCESS_CODES.CREATE]),
      ]);
    },
    fields() {
      return MANGA_SCRAPERS_TABLE_FIELDS;
    },
  },
  filters: {
    validDateFormat,
    scrapingSiteOriginURL,
    momentDaysAgo,
  },
  watch: {
    perPage: async function(newVal) {
      if (newVal === 'All') {
        this.pagination.limit = 100;
      } else {
        this.pagination.limit = parseInt(newVal);
      }
      await this.loadAllMangaScrapers();
    },
  },
  created() {
    // setTitle('Scrapers');
  },
  async mounted() {
    await this.loadScrapers();
    if (this.canListScraper) {
      await this.loadAllMangaScrapers();
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    updateMangaScrapersArray(updatedMangaScraper) {
      this.mangaScrapers = this.mangaScrapers.map(mangaScraper => {
        return mangaScraper.id == updatedMangaScraper.id
          ? updatedMangaScraper
          : mangaScraper;
      });
    },
    deleteFromMangaScrapersArray(mangaScraper) {
      this.mangaScrapers = this.mangaScrapers.filter(
        e => e.id != mangaScraper.id
      );
    },
    getMangaSiteURL(mangaScraper) {
      if (!!mangaScraper.scraper.linkFormat)
        return mangaScraper.scraper.linkFormat.replace(
          '{0}',
          mangaScraper.siteMangaId
        );
      return '';
    },
    getCometMangaSiteURL(mangaScraper) {
      return `/mangas/edit/${mangaScraper.manga.id}/scrapers/`;
    },
    handleMoreInfoButtonClick(rowId) {
      this.$refs.scrapertable.handleDetailRowToggleById(rowId);
    },
    isDetailedRowVisbleForRowId(rowId) {
      return this.$refs.scrapertable.isDetailedRowVisible(rowId);
    },
    async loadScrapers() {
      this.scrapers = [];

      try {
        const { scrapersList } = await fetchScrapers();
        this.scrapers = {};
        scrapersList.map(scraper => {
          if (!(scraper.id in this.scrapers)) {
            this.scrapers[scraper.id] = scraper;
          }
        });
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
    },
    async loadAllMangaScrapers(page = 1) {
      this.setLoading(true);
      try {
        const { allMangaScrapersList } = await fetchAllMangaScrapers(
          this.pagination.limit,
          page
        );
        this.mangaScrapers = allMangaScrapersList.mangaRelations;
        this.pagination = {
          ...this.pagination,
          currentPage: allMangaScrapersList.currentPage,
          pages: allMangaScrapersList.pages,
          total: allMangaScrapersList.total,
        };
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
      this.setLoading(false);
    },
  },
};
</script>
